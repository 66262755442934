/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hlavná"}>
        <SiteHeader />

        <Column className="css-1d4rsxt --style4 --left bg--bottom --full" name={"introduction"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style='color: white;'>Expert financial consulting</span>"}>
              </Title>

              <Text className="text-box fs--20 w--300" style={{"maxWidth":800}} content={"<span style='color: white;'>Entrust your finances to a seasoned expert. I provide expert financial assistance with regard to loan financing, insurance, and investments.</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002 lh--1 mt--30" use={"page"} href={""} page={"21897"} content={"Let's meet"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"about-me"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --left el--2 pb--10 pt--10 flex--center" anim={""} animS={"5"} style={{"maxWidth":1310}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":497}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Entrust your finances to a seasoned expert."}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/130/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/130/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/130/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/130/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/130/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/130/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--50 pt--50" name={"services"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant);'>Loan financing</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant);'>Insurance</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant);'>Investing</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1d4rsxt pb--80 pt--80" name={"contact"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"<span style='color: white;'>Entrust your finances to a seasoned expert.</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002" use={"page"} page={"21897"} content={"Contact me"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Novak Consulting"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"722 Finch Street, Asbury Park, NJ 07712<br>USA"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Contact"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"510-851-4014<br>email@saywebpage.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}